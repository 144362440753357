import React from 'react';
import {SEO, MainLayout, NowPlaying} from '../components';

export default function AppIndex() {
	const nowPlaying = <NowPlaying />;
	return (
		<>
			<SEO title="Home" />
			<div id="portal-root" />
			<MainLayout title="jtsmills" subTitle={nowPlaying} />
		</>
	);
}
